body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* gray/800 */

  color: #1f2937;
  background: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.contentTitle {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */

  /* gray/900 */

  color: #111827;
}

.primaryBtn {
  background: #4338ca !important;
  width: 244px;
}

.secondaryBtn {
  width: 244px;
}

body > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
  display: none;
}

.ant-spin-nested-loading > div > .ant-spin {
  /* height: 100vh !important; */
  max-height: fit-content !important;
  position: fixed;
  z-index: 2000;
  background-color: rgba(241, 244, 248, 0.4);
}

.ant-spin-container {
  /* height: 100vh !important; */
  position: relative;
}

.ant-spin-container::after {
  z-index: 3000;
}

.ant-spin-container.ant-spin-blur {
  height: 100vh;
  max-height: fit-content;
}
